
.subsCard {
  width: 25.172916vw;
  min-height: 35.409583vw;
  border-radius: 20px;    
  border-radius: 1.0416vw;
  position: relative;
  padding-top:1.927083vw;
  padding-bottom: max(11.875vw);
  cursor: default;
}

.subsCard::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #32323250;
    border-radius: 1.0416vw;
    z-index: 0;
    transition: all 0.25s;
    top:0
}
.subsCard:hover::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #59595950;
    border-radius: 1.0416vw;
    z-index: 0;
}
.subsCard > * {
    z-index: 1;
    position: relative;
}
.cardHeader {
   border-bottom: 1px solid #fff;
   margin-left:1.4583vw;
   margin-right: 1.302083vw;
   display: flex;
   justify-content: space-between;
   padding-bottom: max(10px,0.52083vw);
}
.packageName {
    width: 50%;
    font-size: max(1.0416vw);
    font-family: 'Poppins Medium';
    line-height: 2.135416vw;
    text-transform: uppercase;
    font-weight: normal;
}
.packageName span {
    display: block;
    font-size: max(1.822916vw);
    line-height: inherit;
    font-family: 'Poppins SemiBold';
}
.packagePrice {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}
.priceBox {
    display: flex;
    gap: max(1.04166vw);
    align-items: center;
    justify-content: flex-end;
}
.discounted {
    font-size: 1.5625vw;
    color: #1AFF00;
    font-family: 'Poppins Medium';
    line-height: max(2.39583vw);
}
.actual {
    text-decoration: line-through;
    font-size: max(12px,0.83333vw);
}
.subsType {
    display: flex;
    gap: 0.5vw;
    justify-content: flex-end;
}
.subsType .radioBox{
    display: flex;
    position: relative;
    align-items: center;
  }
  
.subsType .radioBox input[type=radio]{
    position: absolute;
    visibility: hidden;
  }
  
  .subsType .radioBox label {
    display: block;
    position: relative;
    font-weight: 300;
    font-size: max(12px,0.83333vw);
    margin: 0px auto;
    height: max(16px,1.14583vw);
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
    padding-left: max(24px,1.71875vw);
    color: #ffffff50;
}
  

.subsType .radioBox .check {
    display: block;
    position: absolute;
    border: 3px solid #fff;
    border-radius: 100%;
    height: 23px;
    width: 23px;
    top: 0px;
    left: 0px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
}  
.subsType .radioBox .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 13px;
    width: 13px;
    top: 3px;
    left: 3px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}
  
  input[type=radio]:checked ~ .check {
    border: 2px solid #fff;
  }
  
  input[type=radio]:checked ~ .check::before{
    background: #fff;
  }
  
  input[type=radio]:checked ~ label{
    color: #ffffff;
  }
  .cardBody {
    padding-top:max(20px,1.97916vw);
    text-align: center;
    padding-left:max(10px,1.0416vw);
    padding-right:max(10px,1.0416vw)
  }
  .cardBody h4 {
    font-family: 'Poppins Light';
    font-size: max(18px,1.5625vw);
    font-weight: normal;
    line-height: max(32px,2.39583vw);
  }
  .cardBody p {
    font-size: max(12px,0.83333vw);
    font-weight: normal;
    line-height: max(20px,1.302083vw);
  }
  .platforms-list {
    padding-top: max(20px,1.822916vw);
    padding-bottom: max(20px,1.822916vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.45vw;
    flex-grow: 1;
    margin: auto;
}
  .platforms-list li {
    max-width: max(3.44583vw);
    display: flex
  }
 
  .platforms-list.greate-10 {
    width: 100%;
  }
  .platforms-list.equal-10  {
    width: 90%;    
  }
  .platforms-list.equal-8  {
    width: 80%;
  }
  .platforms-list.equal-6  {
    width: 60%;
  }
  .platforms-list li img {
    max-width: 100%;
  }
  .btn-subs {
    width: 17.560416vw;
    height: 3.64583vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E42956;
    border-radius: max(10px,0.78125vw);
    color: #fff;
    font-size: max(16px,1.25vw);
    outline: 0;
    border-width: 0;
    margin:max(20px,1.822916vw)  auto;
    cursor: pointer;
    text-decoration: none;
}
.btn-readmore {
    color:#FF98B1;
    font-size: 16px;
    line-height: 25px;
    text-decoration: none;
    background:none;
    outline: 0;
    border: 0;
}
.btn-readmore::after {
    content: ">>";
    padding-left: 7px;
    line-height: 20px;
}
.bestBuy {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  top:0;
}
.bestBuy-wrap {
  width: max(100px,7.2916vw);
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;

}
.bestBuy-wrap img {
  max-width: 100%;
}
.bestBuy-wrap span {
  font-size: max(10px,0.83333vw);
  position: absolute;
  font-family: 'Poppins Medium';
}

@media screen and (max-width:991px) {

  .subsCard {
    width: 48%;
    padding-top: 25px;
    padding-bottom: max(17.875vw);
}
.packageName {
  font-size: 14px;
  line-height: normal;
}
.packageName span {
  font-size: 18px;
}
.bestBuy-wrap {
  width: max(84px,7.2916vw);
}
.discounted {
  font-size: 18px;
  line-height: normal;
}
.actual {
  font-size: max(10px,0.83333vw);
}
.platforms-list li {
  max-width: max(5.44583vw);
}
.cardBody p {
  font-size: max(10px,0.83333vw);
  font-weight: normal;
  line-height: max(20px,1.302083vw);
}
.saveUp {
  font-size: max(14px,1.14583vw);
}
.btn-subs {
  width: 24.560416vw;
  height: 4.64583vw;
  font-size: 14px;
  border-radius: max(0px,0.78125vw);
}
.btn-readmore {
  font-size: 12px;
}

.subsSteps {
    width: max(63.39583vw);
}
.compareSec {
  width: 100%;
}
.subsType {
  display: flex;
  gap: 1.01vw;
  justify-content: flex-end;
  margin-top: 5px;
}
.subsType .radioBox label, [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
  font-size: max(10px,1.083333vw);
  padding-left: max(19px,1.5625vw);
  color: #ffffff50;
  line-height: max(1.8583vw);
}
[type="radio"]:not(:checked) + label:before,[type="radio"]:checked + label:before {
  height: max(14px,1.145833vw);
  width: max(14px,1.145833vw);
}
.bestBuy-wrap {
  width: max(90px,7.2916vw);
}
.bestBuy-wrap span {
  font-size: max(11px,0.83333vw);
  position: absolute;
  font-family: 'Poppins Medium';
}

}
@media screen and (max-width:767px) {
  .subsType {
    align-items: center;
    display: flex;
    gap: 7.35416vw;
    justify-content: center;
}
  .subsCard {
    width: 100%;
    min-height: 130.55vw;
    border-radius: 3.333vw;
    position: relative;
    padding-top: 9.743589vw;
    padding-bottom: 40.41025vw;
}

.subsCard::before {
  border-radius: 3.333vw;
}
.subsCard:hover::before {
  border-radius: 3.333vw;
}
.cardHeader {
  margin-left: 26px;
  margin-right: 26px;
  padding-bottom: max(2.77vw);
  flex-direction: column;
}
.packageName {
  width: 50%;
  font-size: max(4.444vw);
  font-family: 'Poppins Light';
  line-height: 6.666vw;
  text-transform: uppercase;
  font-weight: normal;
}
.packageName span {
  font-size: max(6.666vw);
}
.packagePrice {
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4.722vw;
  padding-bottom: 10px;
}
.priceBox {
  display: flex;
  gap: max(1.04166vw);
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
}
.discounted {
  font-size: 6.666vw;
  line-height: max(8.606vw);
  order: 2;
}
.actual {
  text-decoration: line-through;
  font-size: max(12px,3.333vw);
}

.subsType .radioBox .check {
  border: 2px solid #fff;
  height: 18px;
  width: 18px;
}
.subsType .radioBox .check::before {  
  height: 10px;
  width: 10px;
  top: 2px;
  left: 2px;
}
.cardBody h4 {  
  font-size: 5.555vw;
  font-weight: normal;
  line-height: 8.333vw;
}
.cardBody p {
  font-size: 3.611vw;
  font-weight: normal;
  line-height: 5vw;
  max-width: 75%;
  margin: auto;
}
.platforms-list {
  padding-top: max(20px,1.822916vw);
  padding-bottom: max(20px,1.822916vw);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.388vw;
  flex-grow: 1;
  margin: auto;
}
.platforms-list li {
  max-width: 11.111vw;
  display: flex;
}
.platforms-list li img {
  width: 11.111vw;
}
.saveUp {
  font-size: 3.611vw;
  line-height: 5.555vw;
}
.btn-subs {
  width: 60.277vw;
  height: 13.333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E42956;
  border-radius: max(2.777vw);
  color: #fff;
  font-size: max(5vw);
  outline: 0;
  border-width: 0;
  margin: max(20px,1.822916vw) auto;
  cursor: pointer;
  text-decoration: none;
}
.btn-readmore {
  font-size: 3.611vw;
  line-height: 5.555vw;
}
.faqsSec {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: max(50px,6.25vw);
}
.faq__question dt {
  font-size: 4.444vw;
  line-height: 6.111vw;
}

.bestBuy-wrap {
  width: max(32.0512vw);

}
.bestBuy-wrap img {
  max-width: 100%;
}
.bestBuy-wrap span {
  font-size: max(3.589743vw);
  position: absolute;
  font-family: 'Poppins Medium';
}


}
.bottomWrap {
  display: block;
  text-align: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}