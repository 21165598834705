footer {
    background-color: #1C1C1C;
    min-height: 10.10416vw;
    display: flex;
    align-items: center;
    padding-left: 18.4375vw;
    padding-right: 18.4375vw;
    justify-content: space-between;
}
.footeleft {
    width: 34.89583vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.footeright {
    width: 25.916vw;
    justify-content: flex-end;
    display: flex;
    align-items: center;
}
.footer {
    font-family: 'Poppins SemiBold';
}
.foot-list {
    display: flex;
    gap: 20px;
}
.footxt {
    width: max(100px,8.333vw);
    font-size:max(14px,1.0416vw);
    opacity: 0.7;
    border-right: 1px solid;
}
.footeleft .footxt {
    margin-right: 20px;
}
footer img {
    max-width: 100%;
}
.footeleft ul li:last-child img {
    max-width: 90%;
}
.footeright ul li:first-child img {
    max-width: 90%;
}

@media screen and (max-width:767px) {
    footer {
        padding-top: 5.555vw;
        padding-bottom: 5.555vw;
        flex-direction: column;
        align-items: center;
        padding-left:5.555vw;
        padding-right:5.555vw;
        gap: 4.44vw;
    }
    .footeleft {
        width: 100%;
        flex-direction: column;
        gap: 4.44vw;
    }
    .footeright {
        width: 100%;
        flex-direction: column;
        gap: 4.44vw;
    }
    .footxt {
        margin-right: 0;
        border-right: 0;
        border-bottom: 1px solid #eee;
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
        font-size: 4.444vw;
    line-height: 6.111vw;
    }
    .foot-list {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .foot-list li {
        max-width: calc(50% - 20px);
    }
}