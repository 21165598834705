header {
    background-color: #000;
    min-height: 4.166vw;
    display: flex;
    align-items: center;
    padding-left: 5.2083vw;
}
.logo {
    max-width: 9.635416vw;
    display: flex;
}
.logo img {
    max-width: 100%;
}

@media screen and (max-width:767px) {
    body {
        padding-top:14.35897vw
    }
    header {
        background-color: #000;
        min-height: 4.166vw;
        display: flex;
        align-items: center;
        padding-left: 5.2083vw;
        position: fixed;
        width: 100%;
        z-index: 999;
        top: 0;
    }
    header {
        padding-left: 0;
        justify-content: center;
        padding-top:15px;
        padding-bottom: 15px;
    }
    .logo {
        min-width: 136px;
    }
}

body {
    cursor: default;
}